import { NgxLoggerLevel } from 'ngx-logger';
export const environment = {
  production: false,
  baseUrl: 'https://www.dox2uqa.com:86',
  baseURL97: 'https://www.dox2uqa.com:82',
  baseOrigin: "https://dox2uqa.com",
  baseUrlWS: 'https://www.get2dox.com:118',
  baseUrlWSIcon: 'https://www.get2dox.com:131',
  prepaid:"https://payments.dox2uqa.com:8443",
  baseapp: "https://app.dox2uqa.com",
  httpUrl : "https://",
  domain : "dox2uqa.com",
  logLevel: NgxLoggerLevel.DEBUG,
  serverLogLevel: NgxLoggerLevel.DEBUG,
  enableSourceMaps: false,
  elasticdbaseurl : 'https://www.dox2uqa.com:96',
  paymentBaseUrl: 'https://www.dox2uqa.com:105',
  hostName: 'www.dox2uqa.com',
  port: '86',
  username: 'bac68c55fd67ef746bbf',
  password: '8334a0506e22299fbaf9',
  apiKey: 'Bearer e2b2a8562e0258cad74572d1522d1919',
  DownloadTallyMessageID: '86',
  PartnerFormsMessageID: '113',
  ContactUsFormMessageID: '84',
  ConsultationFormMessageID: '83',
  BlogNewsletterMessageID: '82',
  EbookDownloadMessageID: '81',
  CreateWSMessageID: '80',
  spid: 10186
  };
